import React from "react"
import MediumButton from "../buttons/MediumButton";

const MiddleBannerMap = () =>{
    return (
    <>
    <section className="container-fluid bg-middlebanner">
        <div className="row justify-content-center align-items-center gy-4">
            <div className="col-12 col-xl-6 text-center text-xl-start py-4 ps-xl-5">
        <h3 className="fw-600 text-capitalize pb-4 font-pri font-heading text-white lh-44-middle-banner">
        Medical Care at your doorstep: <br className="d-none d-sm-block"/> Bringing quality healthcare to your neighborhood
        </h3>
        <a href="https://maps.app.goo.gl/3Ai4ZLnifQva9xPN8" className="" target="_blank">
        <button className='btn-customization md-btn bg-white text-dark'>
            View on Google Map
        </button>
        </a>
            </div>
            <div className="col-12 col-xl-6 pb-3">
                <iframe className="w-100 d-none d-md-block" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3605.2996061250265!2d55.3965913!3d25.361273399999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5a2f263abc51%3A0x18ed7a3e9f2d670e!2sSameena%20Medical%20Center!5e0!3m2!1sen!2s!4v1687149959594!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                <iframe className="w-100 d-block d-md-none" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3605.2996061250265!2d55.3965913!3d25.361273399999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5a2f263abc51%3A0x18ed7a3e9f2d670e!2sSameena%20Medical%20Center!5e0!3m2!1sen!2s!4v1687149959594!5m2!1sen!2s" width="600" height="320" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </section>
    </>
    )
}

export default MiddleBannerMap;