import React from "react"
import Banner_mb from '../../assets/images/banner_mb/Banner1.png'
import SmallButton2 from "../buttons/SmallButton2";
import BAAForm from "./BAAForm";

const CommonBanner = (props) =>{
    return (
    <>
    <section className="container-fluid px-0 position-relative pb-4">
        <img className="w-100 d-none d-lg-block" src={props.BgImage} alt={props.Alt}/>
        <img className="w-100 d-block d-lg-none mt-3"  src={Banner_mb} alt={props.Alt} />
        <div className="common-banner-text ms-md-5 mx-3">
        {/* <h3 className="font-pri font-sub-banner col-sec  d-flex ">
            {props.Title1}
        </h3> */}
       <h1 className="font-pri font-banner text-capitalize font-pri-bold col-sec  pt-4 pb-2"> 
       {props.Title2} {props.br} {props.Title2_2}<br/>  </h1>
       <h3 className="font-pri font-sub-banner col-sec "> {props.Title3} <br className="d-none d-md-block"/> {props.Title3_3}
       </h3>
     
       <div className="py-md-2"></div>
       {/* <a href="tel:+97165559010" target="_blank">
        <SmallButton2
        Title="Call us now"
        />
       </a> */}
        </div>
    </section>
    </>
    )
}

export default CommonBanner;