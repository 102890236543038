import React from "react"

const DoctorBanner = (props) =>{
    return (
        <>
        {/* 1st Section Content */}
        <div className="container-fluid">
  <div className="row gy-4 font-pri justify-content-center align-items-center">
  <div className="d-none d-xl-block col-11 col-xl-4 ">
  <img className="w-100 px-md-5" src={props.Img} alt="Dr Suleha Razi - General Practitioner In Sharjah" />
    </div>
    <div className="col-11 col-xl-5 ps-xl-5">
    <div className="">
        <h3 className="col-pri font-doctor fw-600 font-pri-extrabold">
        {props.SubTitle} 
        </h3>
        <h3 className="fw-600 font-doctor">
        {props.Title1} <span className=""> {props.Title2} </span>
        </h3>
        <hr className="hr-heading col-pri w-25"/>
        <p className="font-para fw-400">
            {props.Para}
        </p>
    </div>
    </div>
    <div className="col-11 col-xl-5 d-block d-xl-none">
        <img className="w-100" src={props.Img} alt="Dr" />
    </div>
  </div>
</div>        
        </>

    )
}

export default DoctorBanner;