
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import TwoImgsTwoTexts from "../components/common/TwoImgsTwoTexts";
import TakeActionToday from "../components/common/TakeActionToday";
import {GeneralMedicineSchema} from '../components/Schemas/GeneralMedicineSchema';


// Sources
import Img1 from "../assets/images/ourservices/GeneralMedicine/Img1.webp"
import Img2 from "../assets/images/ourservices/GeneralMedicine/Img2.webp"

import BgImage from "../assets/images/ourservices/GeneralMedicine/banner.webp";
import HeadingandPara from '../components/common/HeadingandPara';
import MiddleSection from '../components/ourservices/MiddleSection';
import Diseases from '../components/ourservices/GeneralMedicine/Diseases';
import MakeAnAppointment from '../components/ourservices/MakeAnAppointment';
import MiddleBannerMap from '../components/common/MiddleBannerMap';

const GeneralMedicine = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
                <>
            <Helmet>
            <title> General Medicine | HelioMed Center </title>
            </Helmet>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Alt="General Medical Center"
        Title1="Empowering Wellness"
        Title2="General Medicine"
        br={<div/>}
        Title2_2="Center, UAE"
        Title3="Compassionate Care, Endless Possibilities! " 
        />
        <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="Discover Optimal Health:"
    Title2="Your Pathway to Wellness, UAE"
    Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
    />
    <section className='py-3'>
    <Diseases/>
    </section>
    <div className='px-3'>
    <p className="font-pri font-para px-lg-5">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto.
    </p>    
    </div>
        <div className='pt-4'>
    </div>
    <MakeAnAppointment/>
    <div className='pt-5 mt-lg-3'>
    </div>

    <section className='py-5'>

      <HeadingandPara
      Title1="Routine Check-ups"
      Title2="and Preventive Care"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
    </section>
    <TwoImgsTwoTexts
        Img1={Img1}
        Alt1="general practitioner sharjah"
        Img2={Img2}
        Alt2="general medical center"
        Title1_1="Management of"
        Title1_2="Chronic Conditions"
        hr1={<hr className="hr-heading col-pri w-25" />}
        Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
        Title2_1="Acute Illnesses"
        Title2_2="and Infections"
        hr2={<hr className="hr-heading col-pri w-25" />}
        Para2p1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
        />
    <section className='py-4 py-md-5'>
      <HeadingandPara
      Title1="Referrals and"
      Title2="Coordinated Care"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
      <div className='pt-3' />
      <HeadingandPara
      Title1="Health Promotion"
      Title2="and Education"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
      <div className='pt-3' />
      <HeadingandPara
      Title1="Comprehensive Care"
      Title2="for Undiagnosed Symptoms"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
    </section>
    <MiddleSection
    Title="Discover Exceptional General Medicine Services"
    Para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
    />
    <section className='pt-4 pt-md-5'>
      <HeadingandPara
      Title1="Personalized"
      Title2="Treatment Plans"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
      <div className='pt-3' />
      <HeadingandPara
      Title1="Experience the Power of"
      Title2="General Medicine for a Healthier You"
      Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde natus dicta molestiae corporis beatae non magnam reprehenderit explicabo nesciunt facere autem error recusandae fugit neque ipsum, quaerat numquam aliquam corrupti maiores cum quasi expedita! Sint ut corrupti, ducimus exercitationem eius enim dignissimos mollitia porro molestias explicabo aspernatur cum adipisci labore officia optio at. Obcaecati maiores ipsum, earum officia doloremque unde, itaque quia veniam cum aspernatur dignissimos illum quisquam. Tenetur veritatis magnam sunt architecto consectetur quis delectus voluptatem fugit hic iusto."
      />
    </section>
    <div className='pt-4 pt-md-5'/>
      <MiddleBannerMap/>
      <div className='pt-5'/>
    <TakeActionToday/>
    <Footer/>
    </>
  );
}

export default GeneralMedicine;
