
import React from 'react';
import DiseasesCol from '../GeneralMedicine/DiseasesCol'
import Icon1 from '../../../assets/images/ourservices/GeneralMedicine/1.png'
import Icon2 from '../../../assets/images/ourservices/GeneralMedicine/2.png'
import Icon3 from '../../../assets/images/ourservices/GeneralMedicine/3.png'
import Icon4 from '../../../assets/images/ourservices/GeneralMedicine/4.png'
import Icon5 from '../../../assets/images/ourservices/GeneralMedicine/5.png'
import Icon6 from '../../../assets/images/ourservices/GeneralMedicine/6.png'
import Icon7 from '../../../assets/images/ourservices/GeneralMedicine/7.png'
import Icon8 from '../../../assets/images/ourservices/GeneralMedicine/8.png'
import Icon9 from '../../../assets/images/ourservices/GeneralMedicine/9.png'


const Diseases = () => {

  return (
    <>
    <section className='container-fluid py-4 px-xl-5'>
        <div className='row justify-content-evenly gy-4 font-pri gx-0'>
            <DiseasesCol
            Icon={Icon1}
            Alt="Fever Treatment In Sharjah"
            Title1_1="Fever"
            Title1_2=""
            Para="Persistent or high-grade fever that lasts for an extended period."
            />
            
            <DiseasesCol
            Icon={Icon2}
            Alt="Respiratory Treatment In Sharjah"
            Title1_1="Respiratory"
            Title1_2="issues"
            Para="Difficulty breathing, shortness of breath, persistent cough, or chest pain."
            />
            
            <DiseasesCol
            Icon={Icon3}
            Alt="Gastrointestinal Treatment In Sharjah"
            Title1_1="Gastrointestinal"
            Title1_2="problems"
            Para="Severe abdominal pain, persistent vomiting, diarrhea, or blood in stool."
            />

            <DiseasesCol
            Icon={Icon4}
            Alt="Treatment for pain In Sharjah"
            Title1_1="Pain or"
            Title1_2="discomfort"
            Para="Unexplained or severe pain in any body part."
            />
            
            <DiseasesCol
            Icon={Icon5}
            Alt="Fatigue Treatment In Sharjah"
            Title1_1="Fatigue"
            Title1_2=""
            Para="Persistent tiredness or lack of energy."
            />
            <DiseasesCol
            Icon={Icon6}
            Alt="Treatment for Sking conditions In Sharjah"
            Title1_1="Skin"
            Title1_2="conditions"
            Para="Rashes, persistent itching, or unusual changes in the skin."
            />
            
            <DiseasesCol
            Icon={Icon7}
            Alt="Urinary Treatment In Sharjah"
            Title1_1="Urinary"
            Title1_2="issues"
            Para="Painful urination, frequent urination, or blood in the urine."
            />
            
            <DiseasesCol
            Icon={Icon8}
            Alt="Headaches Treatment In Sharjah"
            Title1_1="Headaches"
            Title1_2=""
            Para="Frequent or severe headaches that are not relieved with over-the-counter medication."
            />

            <DiseasesCol
            Icon={Icon9}
            Alt="Mental health Treatment In Sharjah"
            Title1_1="Mental health "
            Title1_2="concerns"
            Para="Anxiety, depression, mood swings, or difficulty coping with daily life."
            />
        </div> 
    </section>
    </>
  );
}

export default Diseases;
