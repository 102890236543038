
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import DoctorBanner from '../components/common/DoctorBanner';
import OurTeam from '../components/aboutus/OurTeam';
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import Img from '../assets/images/providers/Img4.png';
import Img2 from '../assets/images/providers/Img1.png';

import BgImage from "../assets/images/providers/Banner1.png";

const Providers = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
                <>
            <Helmet>
              <title>
              Providers | HelioMed Center
              </title>  
            </Helmet>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title2="Providers"
        Title3="Medical Experts, Caring Hearts" 
        />
        <div className='pt-4'>
    </div>
    <DoctorBanner 
            SubTitle="Dr. Rafique" 
            Title1="General" 
            Title2="Practitioner"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            Img={Img}
            />
    <div className='pt-5 mt-lg-3'/>

    <div className="container-fluid">
  <div className="row gy-4 font-pri justify-content-center align-items-center">
    <div className="col-11 col-md-5 ps-md-5">
    <div className="">
        <h3 className="col-pri font-doctor fw-600 font-pri-extrabold">
        Dr. Rafique
        </h3>
        <h3 className="fw-600 font-doctor">
        General Practitioner
        </h3>
        <hr className="hr-heading col-pri w-25"/>
        <p className="font-para fw-400">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
        </p>
    </div>
    </div>
    <div className="col-11 col-md-4">
    <img className="w-100 px-md-5" src={Img2} alt="General Practitioner" />
    </div>
  </div>
</div>  

<div className='pt-5 mt-lg-3'/>

        <OurTeam/>
    <MiddleBannerMap/>
    <div className='pt-5'/>

    <TakeActionToday/>
    <Footer/>
    </>
  );
}

export default Providers;
