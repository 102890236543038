import React from 'react';





const SmallerButton = (props) =>{
    return(
        <>
        <button className='bg-white btn-customization smlr-btn text-black w-100'>
            {props.Title}
        </button>
        </>
    )
}

export default SmallerButton;