import React from "react"
import { Quote } from "react-bootstrap-icons";


const PatientTestimonials = () =>{
    return (
    <>
        <section className="container-fluid">
    <div className="row font-pri">
    <div className="col-12 text-center" > 
            <h3 className="fw-600 font-heading">
            Patient <span className="font-pri-extrabold col-pri"> Testimonials </span>
            </h3>
            {/* <hr className="hr-center col-sec"/> */}
            </div>
            </div>
    </section>

    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
    <section className="container-fluid pb-4">
        <div className="row font-pri pt-4 justify-content-center gy-4">
            {/* Testimonial 1 */}
            <div className="col-11 col-md-3 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold fs-22-services">
                SARA
                </h3>
                <p className="fs-16-services">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit?
                </p>
            </div>
            {/* Testimonial 2 */}
            <div className="col-11 col-md-3 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold fs-22-services">
                SARA
                </h3>
                <p className="fs-16-services">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit?
                </p>
            </div>
            {/* Testimonial 3 */}
            <div className="col-11 col-md-3 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold fs-22-services">
                SARA
                </h3>
                <p className="fs-16-services">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit?
                </p>
            </div>
        </div>
        </section>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
    <section className="container-fluid py-4">
        <div className="row font-pri pt-4 justify-content-center gy-4">
            {/* Testimonial 4 */}
            <div className="col-11 col-md-3 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold fs-22-services">
                SARA
                </h3>
                <p className="fs-16-services">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit?
                </p>
            </div>
            {/* Testimonial 5 */}
            <div className="col-11 col-md-3 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold fs-22-services">
                SARA
                </h3>
                <p className="fs-16-services">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit?
                </p>
            </div>
        </div>
        </section>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>











    </>
    )
}

export default PatientTestimonials;