import React from "react"
import Icon1 from '../../assets/images/ourservices/main/Icon1.webp'
import Icon2 from '../../assets/images/ourservices/main/Icon2.webp'
import Icon3 from '../../assets/images/ourservices/main/Icon3.webp'
import Icon4 from '../../assets/images/ourservices/main/Icon4.webp'
import Icon5 from '../../assets/images/ourservices/main/Icon5.webp'
import Icon6 from '../../assets/images/ourservices/main/Icon6.webp'
import Icon7 from '../../assets/images/ourservices/main/Icon7.webp'
import Icon8 from '../../assets/images/ourservices/main/Icon8.webp'
import Icon9 from '../../assets/images/ourservices/main/Icon9.webp'
import ReadMoreOrLess from "./ReadMoreOrLess";



const OurServices = () =>{
    return (
    <>

<section className="container-fluid pb-5 px-lg-5">
        <div className="row justify-content-evenly gy-5 pt-5 font-pri px-lg-5 ">
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon1}
            Alt="General Medicine icon"
            Title="General Medicine"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon2}
            Alt="General Dentistry Icon"
            Title="General Dentistry"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon3}
            Alt="Cosmetic Dentistry Icon"
            Title="Cosmetic Dentistry"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
        </div>
        <div className="row justify-content-evenly gy-5 pt-5 font-pri px-lg-5">
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon4}
            Alt="Laser Therapy for Pain Management Icon"
            Title="Laser Therapy for Pain Management"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon5}
            Alt="Home Health care Services Icon"
            Title="Home Healthcare Services"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon6}
            Title="IV Therapy"
            Alt="IV Therapy Icon"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
        </div>
        <div className="row justify-content-evenly gy-5 pt-5 font-pri px-lg-5">
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon7}
            Title="Blood Testing/Lab Services"
            Alt="Blood Testing Icon"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon8}
            Title="Physiotherapy Services"
            Alt="Physiotherapy Icon"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
            <ReadMoreOrLess  Link="/service-1"
            Icon={Icon9}
            Title="PCR Testing service"
            Alt="Covid PCR Testing Icon"
            Para="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
            limit={226}
            />
        </div>
    </section>
    </>
    )
}

export default OurServices;