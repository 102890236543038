
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import OurValues from '../components/aboutus/OurValues'
import CTA from '../components/aboutus/CTA'
import TwoImgsTwoTexts from "../components/common/TwoImgsTwoTexts";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurTeam from '../components/aboutus/OurTeam';
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import Img1 from "../assets/images/aboutus/Img1.webp"
import Img2 from "../assets/images/aboutus/Img2.webp"

import BgImage from "../assets/images/aboutus/Banner1.webp";
import OurValuesImg from "../assets/images/aboutus/OurValues1.webp"

const AboutUs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
    <Helmet>
      <title>
        About Us | HelioMed Center
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Alt="HelioMed Center - About us"
        Title2="About Us"
        Title3="Excellence in Care, a Promise We Uphold!" 
        />
        <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="About"
    Title2="Us"
    Para1="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
    />
    <div className='pt-4 pt-md-5'/>
    <OurValues
    OurValuesImg={OurValuesImg}
    />
    <div className='pt-4 pt-md-5'/>
    <CTA
    SubTitle="Let's build a healthier tomorrow together!"
    Title="Accepting new patients"
    />
    <div className='pt-4 pt-md-5'/>
    <TwoImgsTwoTexts
        Img1={Img2}
        Img2={Img1}
        hr1={<hr className="hr-heading col-pri w-25" />}
        hr2={<hr className="hr-heading col-pri w-25" />}
        Title1_1="Our"
        Title1_2="Mission"
        Para1="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
        Title2_1="Our"
        Title2_2="Vision"
        Para2p1="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
        />
    <div className='pt-4'/>
    <MiddleBannerMap/>
    <OurTeam/>
    <div className='pt-5'/>
    <TakeActionToday/>
    <Footer/>
    </>
  );
}

export default AboutUs;
