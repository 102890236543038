import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

const Error = (props) => {
    useEffect(() => {
        window.document.title = "Page Not Found | Sameena Medical Center"
    }, [])
    return (
        <>
        <Header/>
            <div id="website-responsive">
                <section>
                    <div className='container-zk mt-md-5 mb-md-3 text-center pt-5 pb-md-3 pb-5'>
                        <div className='py-5'>
                        <h4 className='fw-bolder fs-25 mb-4'>This page doesn't seem to exist.</h4>
                        <div className='fs-22'>It looks like the link pointing here was faulty. Maybe try searching?</div>
                        <div className='mt-5'>
                            <Link to='/' className='px-3 py-2 text-decoration-none rounded-3 bg-pri text-white'>
                                Return Home
                            </Link>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        <Footer/>
        </>
    )
}

export default Error;
