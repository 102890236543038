import React from "react"


const HeadingandPara = (props) =>{
    return (
    <>
    <section className="container-fluid px-lg-5">
        <div className="font-pri px-3"> 
        <h3 className="fw-600 font-heading pb-3">
            {props.Title1} <span className="font-pri-extrabold col-pri"> {props.Title2} </span>
            </h3>
            {props.hr}
            <p className="font-para">
                {props.Para1} {props.br1}
                {props.Para2} {props.br2}
                {props.Para3} {props.br3}
                {props.Para4}
            </p>
        </div>
    </section>
    </>
    )
}

export default HeadingandPara;