
import React from 'react';

const Diseases = (props) => {

  return (
    <>
            <div className='text-center col-5 col-sm-3 col-md-1'>
              <img className=' shadow w-diseases p-3 rounded-3 bg-pri2' src={props.Icon} alt={props.Alt} />
                <div className='pt-3'>
                <h3 className='fs-14 fw-600'>
                {props.Title1_1} <br/> {props.Title1_2} 
                </h3>
                {/* <p className='fs-16'>
                {props.Para}
                </p> */}
                </div>
            </div>
    </>
  );
}

export default Diseases;
