import React from 'react';

const MediumButton = (props) =>{
    return(
        <>
        <button className='btn-customization md-btn bg-white text-dark'>
            {props.Title}
        </button>
        </>
    )
}

export default MediumButton;