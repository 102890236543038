import React from "react"
import {Link} from 'react-router-dom'
import MediumButton from "../buttons/MediumButton";

const MakeAnAppointment = () =>{
    return (
    <>
    
    <section className="container-fluid">
    <div className="row justify-content-center font-pri">
    <div className="col-11 col-md-6 text-center text-white rounded-5 bg-pri py-5">
    <h3 className="fw-400 text-capitalize middlecta-sub-heading px-3 pb-2 lh-cta">
    Let's build a healthier tomorrow together!
        </h3>
        <h3 className=" font-pri-bold text-uppercase middlecta-heading">
        Make an appointment
        </h3>
        <div className="pt-3">
        {/* <a  href="tel:+97165559010" target="_blank">
            <MediumButton
            Title="Call Now"
            />
        </a> */}
                <Link className="me-3" to="/contact-us">
            <MediumButton Title="Contact Us"/>
        </Link>
        </div>
        </div>
            </div>
    </section>
    </>
    )
}

export default MakeAnAppointment;