import React from "react"
import DividerImg from '../../assets/images/home/Divider.webp'


const BelowBanner = (props) =>{
    return (
    <>
    <section className="container-fluid">
        <div className="text-center font-pri px-2"> 
        <h2 className="fw-600 font-heading">
            {props.Title1} {props.br} <span className="font-pri-extrabold col-pri"> {props.Title2} </span>
            </h2>
            <img className="d-none d-md-inline" src={DividerImg} alt="Divider" style={{width: "270px"}}/>
            <img className="d-inline d-md-none" src={DividerImg} alt="Divider" style={{width: "170px"}}/>
            {/* <hr className="hr-center col-sec"/> */}
            <p className=" text-start font-para px-lg-5 pt-4">
                {props.Para1} {props.br1}
                {props.Para2} {props.br2}
                {props.Para3}
            </p>
        </div>
    </section>
    </>
    )
}

export default BelowBanner;