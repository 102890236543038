
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HomeBanner from '../components/home/HomeBanner';
import OurServices from '../components/home/OurServices';
import PatientTestimonials from "../components/home/PatientTestimonials";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import TakeActionToday from "../components/common/TakeActionToday";
import BelowBanner from '../components/common/BelowBanner';
import Img from '../assets/images/home/Img.webp';
import BgImage1 from "../assets/images/home/Banner1.webp";
import BgImageMb from "../assets/images/home/Banner1Mb.png";
import Team from "../assets/images/home/Team.webp";

const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Home | HelioMed Center"
  },[])
  return (
    <>
    <Helmet>
      <title> Home | HelioMed Center </title>
    </Helmet>
    <Header/>
    <HomeBanner
    BgImage1={BgImage1}
    BgImageMb={BgImageMb}
    />
    <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="Unparalleled Medical &"
    Title2="Dental Expertise"
    Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit? Adipisci corporis iusto quam cupiditate at minus accusamus, fugit minima nostrum non nobis aliquid repellat autem velit voluptas atque eum delectus deserunt facere. Quos ipsa quod voluptatem."
    />
    <div className="pt-4 pt-md-5 container-fluid">
  <div className="row gy-4 font-pri justify-content-center align-items-center">
  <div className="d-none d-xl-block col-11 col-xl-4 ">
  <img className="w-100" src={Img} alt="Dental clinic sharajah" />
    </div>
    <div className="col-12 col-xl-5 ps-xl-5">
    <div className="">
        <h3 className="col-pri font-heading fw-600 text-center text-md-start">
        State-of-the-Art
        </h3>
        <h2 className="fw-600 font-heading font-pri-extrabold text-center text-md-start">
       Facilities and Advanced Technology 
        </h2>
        <hr className="hr-heading col-pri w-25 ms-auto me-auto ms-md-0 me-md-0" />
        <p className="font-para fw-400 ps-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit? Adipisci corporis iusto quam cupiditate at minus accusamus, fugit minima nostrum non nobis aliquid repellat autem velit voluptas atque eum delectus deserunt facere. Quos ipsa quod voluptatem.
        </p>
    </div>
    </div>
    <div className="col-11 col-xl-5 d-block d-xl-none">
        <img className="w-100" src={Img} alt="dental clinic sharjah" />
    </div>
  </div>
</div>  

    <section className="pt-4 pt-md-5 mt-4 mt-md-0">
    <OurServices
        Title={
        <>
        <h3 className="fw-600 font-heading text-center font-pri"> Our <span className="font-pri-extrabold col-pri"> Services </span> </h3>
        </>
      }
      />
        <div className='text-center pt-4 pt-md-5'>
          <Link to="/our-services">
          <button className='btn border-black border-1 rounded-5 text-black font-pri fw-600 px-4 btn-hover'>
            View all services
          </button>
          </Link>
        </div>
        </section>

        <section className="pt-4 pt-md-5 mt-4 mt-md-0">
    <BelowBanner
    Title1="Affordable Rates &"
    Title2="Transparent Pricing"
    Para1="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores! Voluptatibus aliquam cupiditate, deserunt vel quibusdam aut quis iste nostrum commodi neque minima odit fugiat ullam eos debitis distinctio sit? Adipisci corporis iusto quam cupiditate at minus accusamus, fugit minima nostrum non nobis aliquid repellat autem velit voluptas atque eum delectus deserunt facere. Quos ipsa quod voluptatem."
    /> 
    </section>

    <div className="py-4" />
    <BelowBanner
    Title1="Our"
    Title2="Team"
    /> 
    <section className="container-fluid">
    <div className="row font-pri justify-content-center align-items-center">
    <div className="col-11 col-md-7">
      <img src={Team} alt="" className="w-100" />
    </div>
    </div>
    </section>

    <div className="py-4" />

    <section className="">
    <PatientTestimonials/>
    </section>
    <div className='pt-5'/>
    <MiddleBannerMap/>
    <div className='pt-5'/>
    <TakeActionToday/>
    <Footer/>
    </>
  );
}

export default Home;
