import React from "react"
import FooterLogo from "../../assets/images/home/FooterLogo.webp"
import {Link} from 'react-router-dom'
import {GeoAltFill, TelephoneFill, Instagram, Facebook, EnvelopeFill, Whatsapp, PhoneFill, Linkedin, Youtube} from "react-bootstrap-icons"

const Footer = () =>{
    return (
        <>
<div className="container-fluid bg-footer">
  <div className="row justify-content-lg-center font-pri text-white ps-xl-5 ps-1">
    <div className="col-12 col-xl-4 pe-xl-5 py-md-4">
        <div className="pt-md-4 ">
        <img className="footer-logo pb-4" src={FooterLogo} alt="Footer Logo"/>
        <div>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores!
        </p>
        </div>
        </div>
    </div>
    <div className="col-12 col-xl-3 ps-xl-5 pt-xl-4">
        {/* Quick Links */}
        <h2 className="pb-xl-1 footer-heading pt-4" >Quick Links
        <hr className="hr-footer col-pri"/>
        </h2>
        
    <div className="">
        <div className="pb-1"><Link className="text-white hover-sec footer-text"  to="/about-us">About Us</Link> </div>
        <div className="pb-1"><Link className="text-white hover-sec footer-text"  to="/providers">Providers</Link> </div>
        <div className="pb-1"><Link className="text-white hover-sec footer-text"  to="/">FAQs</Link> </div>
        <div className="pb-1"><Link className="text-white hover-sec footer-text"  to="/" >Privacy Policy</Link> </div>
        <div className="pb-1"><Link className="text-white hover-sec footer-text"  to="/service-1" >Home Healthcare Services</Link> </div>
    </div>
    </div>
    {/* Get In Touch */}
    <div className="col-12 col-xl-4 pt-xl-4">
    <h2 className="pb-xl-1 footer-heading pt-4">Get In Touch
    <hr className="hr-footer col-pri"/>
    </h2>
    <div className="">
        <div className="pb-1"><a className="text-white hover-sec footer-text"  target="_blank" rel="noreferrer" href="tel:1234567890" >
           <TelephoneFill className="me-2"/> 123-456-7890 </a></div>

           <div className="pb-1 d-none d-md-block"><a className="text-white hover-sec footer-text"  target="_blank" rel="noreferrer" href="https://web.whatsapp.com" >
           <Whatsapp className="me-2"/> 123-456-7890 </a></div>
           <div className="pb-1 d-block d-md-none"><a className="text-white hover-sec footer-text"  target="_blank" rel="noreferrer" href="https://api.whatsapp.com" >
           <Whatsapp className="me-2"/> 123-456-7890 </a></div>

        <div className="pb-1"><a className="text-white hover-sec footer-text"  target="_blank" rel="noreferrer" href="mailto:samplewebsite@gmail.com" >
        <EnvelopeFill className="me-2"/> samplewebsite@gmail.com </a> </div>
        <div className="pb-1"><a className="text-white hover-sec footer-text"  target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/3Ai4ZLnifQva9xPN8">
        <GeoAltFill className="me-2"/> Dubai, UAE </a> </div>
        </div>
        <div className="" >
            <a className="pe-3  text-white hover-sec " style={{fontSize:"26px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com" >
            <Instagram className=""/></a>
            
            <a className="pe-3  text-white hover-sec " style={{fontSize:"26px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com" >
            <Facebook className=""/></a>

            <a className="pe-3  text-white hover-sec " style={{fontSize:"26px"}}  target="_blank" rel="noreferrer" href="https://www.linkedin.com" >
            <Linkedin className=""/></a>

            <a className="pe-3  text-white hover-sec " style={{fontSize:"26px"}}  target="_blank" rel="noreferrer" href="https://youtube.com" >
            <Youtube className=""/></a>
            </div>
        </div>
  </div>
</div>
<section className="container-fluid text-center bg-light fs-16-copyright font-pri py-md-2 py-1">
    <span className="fw-500"> Copyright © by HelioMed Center 2024. Powered by <a href="https://simplesolutionz.org/" className="text-decoration-none col-sec fw-600" target="_blank" rel="noreferrer">SimpleSolutionz</a> </span> 
    </section>
    <a className="d-none d-md-block" href="https://web.whatsapp.com" target="_blank">
        <Whatsapp
        className="position-fixed bg-success text-white rounded-2 p-1" style={{bottom:"20px", right:"20px", fontSize:"48px"}}
        />
        </a>

        <a className="d-block d-md-none" href="https://api.whatsapp.com" target="_blank">
        <Whatsapp
        className="position-fixed bg-success text-white rounded-2 p-1" style={{bottom:"20px", right:"20px", fontSize:"48px"}}
        />
        </a>
        </>
    )
}

export default Footer;