import React from "react"
import Img1 from "../../assets/images/ourteam/Img1.webp"
import Img2 from "../../assets/images/ourteam/Img2.webp"
import Img3 from "../../assets/images/ourteam/Img3.webp"
import Img4 from "../../assets/images/ourteam/Img4.webp"
import Img5 from "../../assets/images/ourteam/Img5.webp"
import Img6 from "../../assets/images/ourteam/Img6.webp"


const OurServices = () =>{
    return (
    <>
    <section className="container-fluid bg-light shadow py-5 ">
    <h3 className="fw-600 font-heading text-center font-pri">
            Our <span className="font-pri-extrabold col-pri"> Team </span>
            </h3>
            <hr className="hr-center col-sec"/>
            <p className="text-md-center font-pri font-para px-lg-5 px-2">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
            </p>
        <div className="row gy-4 justify-content-center pt-4 px-md-5">
                      
        <div  className="col-11 col-md-6 col-xl-3 text-center ">
            <div className="pb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
                <img className="w-50 pointer" src={Img4} alt="Dr Suleha Razi" />
                </div>
                <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
                Dr. Rafique
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                General Practitioner
                </p>
                
            </div>

            <div className="col-11 col-md-6 col-xl-3 text-center ">
                <div className="pb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop6">
              <img className="w-50 pointer" src={Img6} alt="Dr. M Farrag Zedan - Dental GP In Sharjah" />
            </div>
            <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
            Dr. Rafique
            </h3>
            <p className="text-black fw-400 font-pri font-para px-4">
            General Practitioner
            </p>
          </div>

            <div className="col-11 col-md-6 col-xl-3 text-center ">
            
            <div className="pb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <img className="w-50 pointer" src={Img1} alt="Dr. M Farrag Zedan - Dental GP In Sharjah" />
                </div>
                <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
                Dr. Rafique
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                    Dental GP
                </p>
                
            </div>

            <div className="col-11 col-md-6 col-xl-3 text-center" >
            
            <div data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="pb-4">
                <img className="w-50 pointer" src={Img2} alt="Dhanya Uthaman - Nurse" />
                </div>
                <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
                Dr. Rafique
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                Nurse
                </p>
                
            </div>
        </div>

        <div className="row gy-4 justify-content-center pt-4 px-md-5">
        <div className="col-11 col-md-6 col-xl-3 text-center    ">
            <div className="pb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                <img className="w-50 pointer" src={Img3} alt="Kurt Louie - Nurse" />
                </div>
                <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
                Dr. Rafique
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                Nurse
                </p>   
            </div>
          <div  className="col-11 col-md-6 col-xl-3 text-center">
            <div className="pb-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop5">
              <img className="w-50 pointer" src={Img5} alt="Dr Suleha Razi" />
              </div>
              <h3 className="font-pri-hsb text-black fs-18-providers font-pri-bold">
              Dr. Rafique
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                  Nurse
                  </p>
                  </div>
        </div>
    </section>

    {/* Modal 1 */}
<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Dr. Rafique</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <img className="w-50" src={Img1} alt="Dental General Practitioner" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2"> Dental General Practitioner</div>
     <div className="text-black fw-400 font-pri font-para">
     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
     </div>
      </div>
    </div>
  </div>
</div>


    {/* Modal 2 */}
    <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Dr. Rafique</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <img className="w-50" src={Img2} alt="Dr Sualeha Razi" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2">Nurse</div>
      </div>
    </div>
  </div>
</div>


    {/* Modal 3 */}
    <div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Dr. Rafique</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <img className="w-50" src={Img3} alt="Dr Sualeha Razi" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2">Nurse</div>
      </div>
    </div>
  </div>
</div>


    {/* Modal 4 */}
    <div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 font-pri-hsb text-black font-pri-bold" id="staticBackdropLabel">Dr. Rafique</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <img className="w-50" src={Img4} alt="Dr Sualeha Razi" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2">General Practitioner</div>
     <div className="text-black fw-400 font-pri font-para">
     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
     </div>
      </div>
    </div>
  </div>
</div>

    {/* Modal 5 */}
    <div className="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 font-pri-hsb text-black font-pri-bold" id="staticBackdropLabel"> 
        Dr. Rafique
        </h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <img className="w-50" src={Img5} alt="Dr Sualeha Razi" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2"> Nurse </div>
     <div className="text-black fw-400 font-pri font-para">
     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
     </div>
      </div>
    </div>
  </div>
</div>

    {/* Modal 6 */}
    <div className="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5 font-pri-hsb text-black font-pri-bold" id="staticBackdropLabel">
        Dr. Rafique
        </h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <img className="w-50" src={Img6} alt="Dr Sualeha Razi" />
      <div className="text-black fw-800 font-pri font-para pt-4 pb-2"> General Practitioner </div>
     <div className="text-black fw-400 font-pri font-para">
     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!
     </div>
      </div>
    </div>
  </div>
</div>
    </>
    )
}

export default OurServices;