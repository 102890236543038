import React from "react"
import {Instagram, Facebook, TelephoneFill,EnvelopeFill,GeoAltFill, Whatsapp, Linkedin, Youtube} from 'react-bootstrap-icons';
import InquiryForm from '../common/InquiryForm'

const ContactSection = (props) =>{
    return (
    <>
    <section className='container-fluid pe-md-5'>
                <div className='py-md-0 py-3'>
                <div className="py-md-5">
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='col-sec' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Phone</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head" target="_blank" href="tel:1234567890">123-456-7890</a></div>
                            </div>
                            
                        </div>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <Whatsapp className='col-sec' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Whatsapp</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className='d-none d-md-block'><a className="font-pri font-para hover-sec-head" target="_blank" href="https://web.whatsapp.com">123-456-7890</a></div>
                                    <div className='d-block d-md-none'><a className="font-pri font-para hover-sec-head" target="_blank" href="https://api.whatsapp.com">123-456-7890</a></div>
                            </div>
                            
                        </div>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <EnvelopeFill className='col-sec' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head"  target="_blank" href="mailto:samplewebsite@gmail.com">samplewebsite@gmail.com</a></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-xl-3 mb-3'>
                            <div className='my-2'>
                                <GeoAltFill className='col-sec' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Address</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                                    <div className=''><a className="font-pri font-para hover-sec-head"  target="_blank" href="https://maps.app.goo.gl/3Ai4ZLnifQva9xPN8">
                                        Dubai, UAE
                                    </a>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="container-fluid pt-md-4">
                <div className="row font-pri justify-content-center gy-5">             
                <div className="col-11 col-xl-5 pe-md-5 text-center text-md-start">
                    <InquiryForm/>
                </div>
                    <div className="pb-5 col-12 col-xl-5 ps-xl-5 ms-xl-5 border-start border-2">
                    <div className="w-100 bg-pri py-4 px-4">
                    <h3 className="font-pri-bold font-sub-heading col-sec mt-3">
            WORKING HOURS
        </h3>
        <h4 className="font-pri fs-14 col-sec">
        Home Healthcare Services (24/7)
        </h4>
		<table className="col-sec border-white table-bordered table-sm mt-3 w-100">
			<tbody>
				<tr>
					<td className="fw-400" >Monday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
				<tr>
					<td className="fw-400" >Tuesday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
				<tr>
					<td className="fw-400" >Wednesday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
                <tr>
					<td className="fw-400" >Thursday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
                <tr>
					<td className="fw-400" >Friday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
                <tr>
					<td className="fw-400" >Saturday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
                <tr>
					<td className="fw-400" >Sunday</td>
					<td>8:00AM - 10:00PM</td>
				</tr>
			</tbody>
		</table>
        </div>
        <h3 className="mt-4 font-sub-heading font-pri-extrabold col-pri">
            Follow Us
            </h3>
            <div className="mt-1" >
            <a className="pe-3  hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com" >
            <Instagram className=""/></a>
            
            <a className="pe-3 hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com">
            <Facebook className=""/></a>

            <a className="pe-3 hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.linkedin.com">
            <Linkedin className=""/></a>

            <a className="pe-3 hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://youtube.com">
            <Youtube className=""/></a>

            </div>
        </div>
                </div>
            </section>

           <div className="container-fluid row gx-0 justify-content-center pt-md-5 pt-2">
            <div className="col-12 col-xl-11">
            <iframe className="w-100 d-none d-md-block" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1717648564334!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
            <div className="col-12 col-xl-11 d-md-none d-block p-3">
            <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1717648564334!5m2!1sen!2s" width="600" height="320" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>

            </div>
            </div>
    </>
    )
}

export default ContactSection;