import React from "react"
import { Link } from "react-router-dom"
import MediumButton2 from "../buttons/MediumButton2";


const TakeActionToday = () =>{
    return (
    <>
    <section className="container-fluid pb-5 mt-md-4">
    <div className="row text-center font-pri">
    <div className="col-12">
        <div className="">
        <h3 className="text-capitalize fw-600 font-sub-heading pb-1">
        lorem ipsum dolor sit amet
        </h3>
        <h2 className="text-uppercase col-sec font-pri-extrabold font-heading">
        lorem ipsum dolor sit amet
        </h2>
        </div>
        <div className="pt-3">
        <a href="tel:1234567890" target="_blank" >
            <MediumButton2
            Title="Call Now"
            />
        </a>
        </div>
        </div>
            </div>
    </section>
    </>
    )
}

export default TakeActionToday;