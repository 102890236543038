import React from 'react';

const SmallButton = (props) =>{
    return(
        <>
        <button  type="button" className='btn-customization sm-btn px-4 rounded-5 btn-hover bg-pri'>
           {props.Title}
        </button>
        </>
    )
}

export default SmallButton;