
import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import Home from '../src/pages/Home';
import AboutUs from '../src/pages/AboutUs';
import Providers from '../src/pages/Providers';
import Services from '../src/pages/Services';
import GeneralMedicine from '../src/pages/GeneralMedicine';
import ContactUs from '../src/pages/ContactUs';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/sass/style.scss';
import Error from './components/common/Error';



const App = () => {
  return (
    <>
    {/* Color 1 */}
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/providers" element={<Providers/>} />
          <Route path="/our-services" element={<Services/>} />
          <Route path="/service-1" element={<GeneralMedicine/>} />
          <Route path="/service-2" element={<GeneralMedicine/>} />
          <Route path="/service-3" element={<GeneralMedicine/>} />
          <Route path="/service-4" element={<GeneralMedicine/>} />
          <Route path="/service-5" element={<GeneralMedicine/>} />
          <Route path="/sub-service-1" element={<GeneralMedicine/>} />
          <Route path="/sub-service-2" element={<GeneralMedicine/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="*" element={<Error/>} />

        </Routes>
    </>
  );
}

export default App;
