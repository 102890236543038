
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import ContactSection from '../components/contactus/ContactSection';


// Sources
import BgImage from "../assets/images/contactus/Banner1.webp";

const ContactUs = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
    <Helmet>
      <title>
        Contact Us | HelioMed Center
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title2="Contact Us"
        Title3="Your Questions, Our Expert Answers" 
        />
        <div className='pt-4'>
    </div>
    <ContactSection/>
    <Footer/>
    </>
  );
}

export default ContactUs;
