import React from "react"
import Banner from "../../assets/images/aboutus/BannerOurValues.webp"


const OurValues = (props) =>{
    return (
    <>
    <section className="container-fluid">
        <div className="row justify-content-end ourvalues-banner pt-md-5" style={{backgroundImage:`url(${Banner})`}}>
            <div className="col-12 col-md-4 py-md-5 py-3 text-center mt-md-5 mb-md-3">
            <img className="d-none d-md-block w-100" src={props.OurValuesImg} alt="Our Values" />
                <img className="d-block d-md-none w-50" src={props.OurValuesImg} alt="Our Values" />
                </div>
            <div className="col-12 col-md-6">
            </div>
        </div>
    </section>
    </>
    )
}

export default OurValues;