import React from "react"
import SmallButton from "../buttons/SmallButton";


const HomeBanner = (props) =>{
    return (
    <>
    <section className="container-fluid px-0 bg-sample position-relative ">
        <img className="w-100 d-none d-md-block" src={props.BgImage1} alt="top medical center in Sharjah" />
        <img className="w-100 d-block d-md-none" src={props.BgImageMb} alt="top medical center in Sharjah" />
        <div className="home-banner-text font-pri">
        <h1 className="fw-600 col-sec st-hbt">
        HelioMed Center: 
       <div className="col-sec pb-md-3 t-hbt font-pri-extrabold pt-2">
        Path to Better <br className="d-block d-sm-none"/> Health <br className="d-none d-md-block"/> In Dubai
       </div>
    </h1>
       <a href="tel:1234567890" target="_blank" rel="noreferrer">
        <SmallButton
        Title="Call us now"
        />
        </a>
        </div>
    </section>
    </>
    )
}

export default HomeBanner;