import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

const InquiryForm = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('service_34mfhev','template_d2zhkdf',e.target,'vKG-XBLnS9Vj9_J16').then(res=>{
    console.log(res)
    alert("Your form has been submitted")
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section className="container-fluid mt-lg-4">
        <div className="row justify-content-center align-items-center gy-5 pb-5">
            <div className="col-12 text-center ">
                <div className="">
                <h3 className="font-heading font-pri-extrabold text-uppercase col-pri">
                    Drop us a line
                </h3>
                <div className="text-center col-12 px-4">
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#">
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="name">Name</label>
        <input type="text" className="form-control" id="name" name="name" placeholder="Name" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email</label>
        <input type="email" className="form-control" id="email" name="email" placeholder="Email" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number</label>
        <input type="number" className="form-control" id="phone_number" name="phone_number" placeholder="Phone Number" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="message">Message</label>
        <textarea className="col-pri form-control" id="message" name="message" rows="5" placeholder="Message" required></textarea>
      </div>
      <div className="pt-3">
      <ReCAPTCHA
          sitekey="6LfZjx4nAAAAAMnyR_l8ISMXnPJtao1SC1C2joyh"

    onChange={onChange}/>,
      <button className='w-100 btn-customization md-btn' disabled={!verified}>
            Submit
        </button>
      </div>
    </form>
    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}

export default InquiryForm;