import React from 'react';

const MediumButton2 = (props) =>{
    return(
        <>
        <button className='btn-customization md-btn btn-hover bg-pri'>
            {props.Title}
        </button>
        </>
    )
}

export default MediumButton2;