import React from 'react';
import { Link } from "react-router-dom"
import {ChevronRight, GeoAltFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons"
import SmallerButton from '../buttons/SmallerButton'
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import HeaderLogo from "../../assets/images/home/HeaderLogo1.webp"
import Offcanvas from 'react-bootstrap/Offcanvas';


const Header = () => {
  const location = useLocation();

  const { pathname } = location;  
  const splitLocation = pathname.split("/");

  return (
    <>
    <div className='sticky-md-top'>
        <section>
    {/* Upper Header */}
<div className='container-fluid font-pri bg-white px-lg-5'>
    <div className='row col-font py-2'>
    <div className='col-12 col-lg-4 d-none d-lg-block fw-600 font-upper-heading'>
</div>

<div className='col-12 col-lg-8 text-lg-end'>
    {/* Phone Number */}
    <a href="tel:1234567890" className="pe-lg-4  hover-sec-head "  target="_blank" rel="noreferrer" > 
    <TelephoneFill className='col-pri me-1'/>
        <span className=" col-sec fw-600 fst-normal font-upper-heading"> 123-456-7890 </span>
    </a> 
    <a href="https://web.whatsapp.com" className="pe-lg-4 ps-3 ps-md-0  hover-sec-head "  target="_blank" rel="noreferrer" > 
    <Whatsapp className='col-pri me-1'/>
        <span className=" col-sec fw-600 fst-normal font-upper-heading"> 123-456-7890 </span>
    </a> 

    {/* Br for Mobile  */}
    <br className='d-block d-lg-none' />
    {/* Address */}
    <a href="https://maps.app.goo.gl/3Ai4ZLnifQva9xPN8" className=" hover-sec-head" target="_blank" rel="noreferrer"> 
    <GeoAltFill className='col-pri me-1'/>
        <span className=" col-sec fw-600 fst-normal font-upper-heading"> Dubai, UAE </span>
    </a>
</div>
    </div>
</div>
</section>
      <Navbar expand="lg" className="bg-pri py-0" id="website-nav" style={{borderBottom:"3px solid #3e3e3e"}}>
        <div className='container-fluid px-lg-5'>
            <Navbar.Brand className='navbar-brand bg-white px-2' style={{borderTop:"1px solid #3e3e3e"}}>
              <Link
            className=""
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className="header-logo"
            />
            </Link>
            </Navbar.Brand>
                
                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-0 bg-white' style={{border:"none"}} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="header-logo"
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
            <ul className="navbar-nav d-block d-lg-none">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/about-us">About Us</Link>
        </li><span className='px-md-2'/>

        <li className="nav-item right-menu">
<div class="btn-group dropdown">
<Link  className="nav-link pe-0 hover-services" to="/our-services">
            Services
</Link>
  <Link class="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black" data-bs-toggle="dropdown-menu" aria-expanded="false">
    <span class="visually-hidden">Toggle Dropdown</span>
  </Link>
</div>


          <ul id='mobilemenu' className="dropdown-menu hover1 rounded-0 border-0 shadow-0 pt-1 pb-0 border-top-menu bg-grey">
          <li> <Link className="dropdown-item hover-sec-head pt-2 bg-grey " to="/service-1" >General Medicine</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-grey" to="/service-2" >General Dentistry</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-grey" to="/service-3" >Cosmetic Dentistry</Link></li>

            <li className='right-menu2'>
              <li className='btn-group'>
            <Link className=" dropdown-item hover-sec-head hover-home-healthcare bg-grey"
            to="/service-4" role="button" >
            Home Healthcare Services
            </Link> 
            <Link type="button" class="btn pt-1 dropdown-toggle dropdown-toggle-split border-0" data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
              </Link>
              </li>
            <ul className='dropdown-menu hover2 p-0 rounded-0 ms-3 border-0 border-top-menu bg-grey'>
            <li> <Link className="dropdown-item hover-sec-head pt-2 bg-grey " to="/sub-service-1" >Doctor Consultation</Link></li>
            <li> <Link className="dropdown-item hover-sec-head bg-grey" to="/sub-service-2" >Wound Dressing</Link></li>
            </ul>

            </li>


            <li><Link className="dropdown-item hover-sec-head bg-grey " to="/service-5" >Laser Therapy for Pain Management  </Link></li>
          </ul>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/providers">Providers</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/contact-us"> Contact Us </Link>
        </li><span className='px-md-2'/>
        <li className="mx-lg-4 border-white border-end">
        </li>
        <li className="nav-item py-2 mt-4 py-lg-0">
            <a href="tel:1234567890"  target="_blank" rel="noreferrer" > 
            <button className='text-white btn-customization smlr-btn text-black w-100'>
        Call Now
        </button>
            </a>
        </li>
      </ul>

      {/* Desktop */}
      <ul className="d-none d-lg-flex navbar-nav ms-auto">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/about-us">About Us</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item right-menu">
          <Link  className="nav-link dropdown-toggle" to="/our-services" role="button">
            Services
          </Link>
          <ul className="dropdown-menu hover1">
          <li> <Link className="dropdown-item hover-sec-head bg-white border-bottom" to="/service-1"  >General Medicine</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-white border-bottom" to="/service-2" >General Dentistry</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-white border-bottom" to="/service-3" >Cosmetic Dentistry</Link></li>

            <li className='border-bottom d-none d-lg-block'>
  <li className="btn-group dropstart">
  <Link to="/service-4"  className="dropdown-item hover-sec-head bg-white">
  Home Healthcare Services <ChevronRight/>
    </Link>
  <ul className="dropdown-menu left-menu2">
  <li> <Link className="dropdown-item hover-sec-head bg-white border-bottom" to="/sub-service-1"  >Doctor Consultation</Link></li>
            <li> <Link className="dropdown-item hover-sec-head bg-white" to="/sub-service-2" >Wound Dressing</Link></li>
  </ul>
</li>
</li>
            <li><Link className="dropdown-item hover-sec-head bg-white" to="/service-5" >Laser Therapy for Pain Management  </Link></li>
          </ul>
        </li><span className='px-md-2'/>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/providers">Providers</Link>
        </li><span className='px-md-2'/>
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/contact-us"> Contact Us </Link>
        </li><span className='px-md-2'/>
        <li className="mx-lg-4 border-white border-end">
        </li>
        <li className="nav-item py-2 py-lg-0">
            <a href="tel:1234567890"  target="_blank" rel="noreferrer" > 
            <SmallerButton
            Title= "Call Now"
            />
            </a>
        </li>
      </ul>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
        </div>
    </>
  )
}

export default Header