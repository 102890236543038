import React from "react"
import { Link } from "react-router-dom"
import Img1 from "../../assets/images/home/Img1.webp"
import Img2 from "../../assets/images/home/Img2.webp"
import Img3 from "../../assets/images/home/Img3.webp"

const OurServices = (props) =>{
    return (
    <>
    <section className="container-fluid">
        {props.Title}
        <div className="row gy-4 justify-content-center pt-4">
            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/service-1">
            <div className="pb-4">
                <img className="w-50"src={Img1} alt="General Medicine" />
                </div>
                <h3 className="font-pri text-black font-pri-bold text-uppercase fs-22-services">
                General Medicine
                </h3>
                <p className="font-pri text-black fs-16-services px-4 ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores!
                </p>
                </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <Link className="hover-font" to="/service-1">
            <div className="pb-4">
                <img className="w-50"src={Img2} alt="General Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                General Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores!
                </p>
            </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4">
            <Link className="hover-font" to="/service-1">
            <div className="pb-4">
                <img className="w-50"src={Img3} alt="Cosmetic Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                Cosmetic Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis, fugit ipsa sed officiis minus quidem vitae? Ut, eaque fugit laudantium velit incidunt beatae nulla tenetur iusto quaerat atque molestiae aliquam sapiente illum dolores!
                </p>
            </Link>
            </div>
        </div>
    </section>



{/* 


    <section className="container-fluid">
        {props.Title}
    <div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="row gy-4 justify-content-center pt-4 pb-4 ">
            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/general-medicine">
            <div className="pb-4">
                <img className="w-50"src={Img1} alt="General Medicine" />
                </div>
                <h3 className="font-pri text-black font-pri-bold text-uppercase fs-22-services">
                General Medicine
                </h3>
                <p className="font-pri text-black fs-16-services px-4 ">
                Our General Medicine services offer comprehensive care for all your medical needs. From routine check-ups to diagnosing and managing complex health conditions...
                </p>
                </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <Link className="hover-font" to="/general-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img2} alt="General Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                General Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                Your oral health is based on your overall well-being. At Sameena Medical Center, we understand the importance of a healthy smile. Our General Dentistry services offer comprehensive...
                </p>
            </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4">
            <Link className="hover-font" to="/cosmetic-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img3} alt="Cosmetic Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                Cosmetic Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                A captivating smile can transform not only your appearance but also your confidence. At Sameena Medical Center, our Cosmetic Dentistry services aim to...
                </p>
            </Link>
            </div>
        </div>
    </div>
    <div class="carousel-item">
    <div className="row gy-4 justify-content-center pt-4 pb-4">
            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/general-medicine">
            <div className="pb-4">
                <img className="w-50"src={Img1} alt="General Medicine" />
                </div>
                <h3 className="font-pri text-black font-pri-bold text-uppercase fs-22-services">
                General Medicine
                </h3>
                <p className="font-pri text-black fs-16-services px-4 ">
                Our General Medicine services offer comprehensive care for all your medical needs. From routine check-ups to diagnosing and managing complex health conditions...
                </p>
                </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <Link className="hover-font" to="/general-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img2} alt="General Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                General Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                Your oral health is based on your overall well-being. At Sameena Medical Center, we understand the importance of a healthy smile. Our General Dentistry services offer comprehensive...
                </p>
            </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4">
            <Link className="hover-font" to="/cosmetic-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img3} alt="Cosmetic Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                Cosmetic Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                A captivating smile can transform not only your appearance but also your confidence. At Sameena Medical Center, our Cosmetic Dentistry services aim to...
                </p>
            </Link>
            </div>
        </div>
    </div>
    <div class="carousel-item">
    <div className="row gy-4 justify-content-center pt-4 pb-4">
            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/general-medicine">
            <div className="pb-4">
                <img className="w-50"src={Img1} alt="General Medicine" />
                </div>
                <h3 className="font-pri text-black font-pri-bold text-uppercase fs-22-services">
                General Medicine
                </h3>
                <p className="font-pri text-black fs-16-services px-4 ">
                Our General Medicine services offer comprehensive care for all your medical needs. From routine check-ups to diagnosing and managing complex health conditions...
                </p>
                </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <Link className="hover-font" to="/general-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img2} alt="General Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                General Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                Your oral health is based on your overall well-being. At Sameena Medical Center, we understand the importance of a healthy smile. Our General Dentistry services offer comprehensive...
                </p>
            </Link>
            </div>

            <div className="border col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4">
            <Link className="hover-font" to="/cosmetic-dentistry">
            <div className="pb-4">
                <img className="w-50"src={Img3} alt="Cosmetic Dentistry" />
                </div>
                <h3 className="font-pri-hsb text-black fs-22-services font-pri-bold text-uppercase">
                Cosmetic Dentistry
                </h3>
                <p className="font-pri text-black fs-16-services px-4">
                A captivating smile can transform not only your appearance but also your confidence. At Sameena Medical Center, our Cosmetic Dentistry services aim to...
                </p>
            </Link>
            </div>
        </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </section> */}
    </>
    )
}

export default OurServices;