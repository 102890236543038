import React, { useState } from "react"
import {Link }from 'react-router-dom'

const ReadMoreOrLess = (props) =>{

    const [isReadMoreshown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }
    return (
    <>
    <div className="col-12 col-lg-4 px-4">
           <Link to={props.Link}> <img className="w-icon" src={props.Icon} alt={props.Alt} /> </Link>
        <div className="pt-4">
        <Link className="text-decoration-none" to={props.Link}>   <h3 className="text-black fw-600 fs-one-service">
                {props.Title}
            </h3> </Link>

            <p className="fs-one-service-para">
               {isReadMoreshown ? props.Para : props.Para.substr(0,props.limit)} 
            </p>
            <button onClick={toggleBtn} className="col-pri fs-14 px-2 fw-600 bg-white" style={{border:"2px solid #3e3e3e"}}>
                {isReadMoreshown ? "Read Less" : "Read More"}
            </button>
        </div>
    </div>
    </>
    )
}

export default ReadMoreOrLess;