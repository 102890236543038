import React from "react"
import MiddleSectionImg from '../../assets/images/ourservices/GeneralMedicine/MiddleSectionImg.png'

const MiddleSection = (props) =>{
    return (
    <>
        <section className="container-fluid bg-pri pt-5 pb-md-5">
        <div className="row justify-content-center align-items-center gy-3 font-pri">
        <div className="col-12 col-xl-3 pb-3 pb-md-0">
              <img className="w-100" src={MiddleSectionImg} alt="gp doctor in Sharjah" />
            </div>
            <div className="col-12 col-xl-8 py-4 ps-xl-5 text-white">
            <h3 className="fw-600 font-heading">
            {props.Title}
            </h3>
            <p className="font-para">
                {props.Para}
            </p>
            </div>
        </div>
    </section>
    </>
    )
}

export default MiddleSection;