
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import TakeActionToday from "../components/common/TakeActionToday";
import HeadingandPara from "../components/common/HeadingandPara";

// Sources
import BgImage from '../assets/images/ourservices/main/BgImg.webp'
import OurServices from '../components/ourservices/OurServices';
import MiddleBannerMap from '../components/common/MiddleBannerMap';


const Services = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
                <>
            <Helmet>
              <title>
              Services We Offer | HelioMed Center
              </title>  
            </Helmet>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Alt="medical services"
        Title2="Services We Offer"
        Title3="Where Compassion Meets Excellence " 
        />
        <div className='pt-4'></div>

        <HeadingandPara
        Title1="Transforming Healthcare with"
        Title2="Excellence"
        Para1="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit sapiente molestias velit quo quod dolore omnis cupiditate incidunt suscipit aut a illum architecto ea corporis deserunt, eligendi exercitationem sequi modi quibusdam fugiat est facere minus commodi voluptatibus! Recusandae omnis totam, dolor rerum neque blanditiis cupiditate quos vel nam similique, ipsum eaque optio illum in libero! In quibusdam itaque vero illum eaque saepe, incidunt minus doloremque, quasi velit non vitae, quas aut nemo possimus inventore corrupti quidem! Odit quidem nulla beatae!"
        />
        <OurServices/>
        <div className='pt-4 pt-md-5'/>
        <MiddleBannerMap/>
        <div className='pt-5'/>
    <TakeActionToday/>
    <Footer/>
    </>
  );
}

export default Services;
